import * as ProviderApi from "api/ProviderApi";
import * as ActionTypes from "constants/ActionTypes";
import {
    mapProviderClinicsFromServerToApp,
    mapProviderColumnsFromServerToApp,
    mapProviderGameTemplateFromServerToApp,
    mapProviderChildNodesFromServerToApp,
    mapRedemptionsFromServerToApp,
    mapSingleGameFromServerToApp,
    mapProviderDashboardFromServerToApp, mapClinicGamesFromServerToApp
} from "data/serverMapping";

import filter from "lodash/filter";
import map from "lodash/map";
import {handleErrorResponse} from "utils/request";
import * as ApplicationStateActions from "./ApplicationStateActions";
import {getDefaultErrorHandler} from "./base";

export function getProviderDashboard() {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROVIDER_DASHBOARD_LOADING });

        ProviderApi.getProviderDashboard()
            .then(res => {
                dispatch({
                    type: ActionTypes.PROVIDER_DASHBOARD_LOADED,
                    data: mapProviderDashboardFromServerToApp(res.body),
                });
            })
            .catch(error => {
                handleErrorResponse("loading dashboard", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getProviderClinicsProgramEnrollment({
    nodeId,
    manufacturerId,
    limit = 9999,
    offset = 0,
    orderBy = "clinicName",
    orderDir = "DESC"
}) {
    const searchData = {
        nodeId,
        manufacturerId,
        limit,
        offset,
        orderBy,
        orderDir,
    }
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROVIDER_CLINICS_PROGRAM_ENROLLMENT_LOADING });

        ProviderApi.getProviderClinicsProgramEnrollment(searchData)
            .then(res => {
                dispatch({
                    type: ActionTypes.PROVIDER_CLINICS_PROGRAM_ENROLLMENT_LOADED,
                    results: res.body,
                    nodeId,
                });
            })
            .catch(error => {
                handleErrorResponse("loading clinics programs list", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getProviderGames(userId = null, isDemo=false) {
    return (dispatch) => {
        ProviderApi.getProviderGames(userId, isDemo)
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.PROVIDER_GAMES_LOADED,
                        games: res.body.result,
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading games", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getNode(nodeId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.NODE_LOADING });

        ProviderApi.getNode(nodeId)
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.NODE_LOADED,
                        node: res.body,
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading node", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getChildNodes(nodeId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.NODE_CHILDREN_LOADING });

        ProviderApi.getChildNodes(nodeId)
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.NODE_CHILDREN_LOADED,
                        nodeId,
                        children: mapProviderChildNodesFromServerToApp(res.body),
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading child nodes", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getNodeClinics(nodeId = null) {
    return (dispatch) => {

        ProviderApi.getNodeClinics(nodeId)
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.PROVIDER_NODE_CLINICS_LOADED,
                        nodeId,
                        clinics: mapProviderClinicsFromServerToApp(res.body.clinics),
                        columns: mapProviderColumnsFromServerToApp(res.body.columnProperties)
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading clinics", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function updateCorporateGroupLocationId(locationInfo) {
    return (dispatch) => {
        ProviderApi.updateCorporateGroupLocationId(locationInfo)
            .then(res => {
                dispatch({
                    type: ActionTypes.CORPORATE_GROUP_LOCATION_ID_UPDATE,
                    data: locationInfo
                });
            })
            .catch(error => {
                handleErrorResponse("updating clinic corporate group locationId", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getClinicAggregates(nodeId, year=null) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ProviderApi.getClinicAggregates(nodeId, year)
            .then(res => {
                dispatch({
                    type: ActionTypes.PROVIDER_CLINIC_AGGREGATES_LOADED,
                    nodeId,
                    data: res.body,
                });
            })
            .catch(error => {
                handleErrorResponse("loading clinic aggregates", error);
                getDefaultErrorHandler(dispatch, "loading clinic aggregates")(error);
            })
            .finally(() => {
                dispatch(ApplicationStateActions.endWorking());
            });
    };
}

// TODO: GAMES API : Provider User ID is required for Admins to access data from this API
export function getProviderClinicStats(userId=null, daysCurrent=30) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROVIDER_CLINIC_STATS_LOADING });

        ProviderApi.getProviderGameClinics(userId, daysCurrent)
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.PROVIDER_CLINIC_STATS_LOADED,
                        clinicStats: res.body,
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading clinic stats", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

// TODO: GAMES API : Provider User ID is required for Admins to access data from this API
export function getGameTemplates(userId=null) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROVIDER_GAME_TEMPLATES_LOADING });

        ProviderApi.getGameTemplates(userId)
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.PROVIDER_GAME_TEMPLATES_LOADED,
                        gameTemplates: map(res.body, t => mapProviderGameTemplateFromServerToApp(t)),
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading game templates", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

// TODO: GAMES API : Provider User ID is required for Admins to access data from this API
export function getPreviousGames(userId, isDemo) {

    return (dispatch) => {
        dispatch({ type: ActionTypes.PROVIDER_PREVIOUS_GAMES_LOADING });

        ProviderApi.getPreviousGames(userId, isDemo)
            .then(res => {
                dispatch({
                    type: ActionTypes.PROVIDER_PREVIOUS_GAMES_LOADED,
                    previousGames: res.body,
                });
            })
            .catch(error => {
                handleErrorResponse("loading historic games", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

// TODO: GAMES API : Provider User ID is required for Admins to access data from this API
export function getGameInvites(daysCurrent, userId=null) {
    return (dispatch) => {
        // dispatch({ type: ActionTypes.GAME_INVITES_LOADING });
        ProviderApi.getGameInvites(daysCurrent, userId)
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.GAME_INVITES_LOADED,
                        data: res.body,
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading game invites", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getDemoGameInvites() {
    return (dispatch) => {
        ProviderApi.getDemoGameInvites()
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.GAME_INVITES_LOADED,
                        data: {
                            access: "",
                            result: res.body,
                        },
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading game invites", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getGameProductBrands(userId, productCategoryId=null) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROVIDER_PRODUCT_BRANDS_LOADING });

        ProviderApi.getGameProductBrands(userId, productCategoryId)
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.PROVIDER_PRODUCT_BRANDS_LOADED,
                        gameProductBrands: res.body,
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading game product brands", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getGameProductBrandsForGame(userId, gameType, productCategoryId=null) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROVIDER_PRODUCT_BRANDS_LOADING });

        ProviderApi.getGameProductBrandsForGame(userId, gameType, productCategoryId)
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.PROVIDER_PRODUCT_BRANDS_LOADED,
                        gameProductBrands: res.body,
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading game product brands", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}


export function getDemoGameProductBrands() {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROVIDER_PRODUCT_BRANDS_LOADING });

        ProviderApi.getDemoGameProductBrands()
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.PROVIDER_PRODUCT_BRANDS_LOADED,
                        gameProductBrands: res.body,
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading game product brands", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function sendInvite(inviteData) {
    return (dispatch) => {
        ProviderApi.sendGameInvite(inviteData)
            .then(res => {
                //TODO: Only sending back a "success message," need to know the "expirationDate",
                // would be nice to know the "optInStatus" too.
                if (res.body) {
                    if (res.body.success === true) {
                        dispatch({
                            type: ActionTypes.GAME_INVITE_UPDATED,
                            clinicId: inviteData.clinicId,
                            year: inviteData.inviteYear,
                        });
                    }
                }
            })
            .catch(error => {
                handleErrorResponse("sending game invite", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

// GAMES API : Provider User ID is required for Admins to access data from this API
export function getBiGamesClinics(userId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROVIDER_BI_GAMES_CLINICS_LOADING });

        ProviderApi.getBiGamesClinics(userId)
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.PROVIDER_BI_GAMES_CLINICS_LOADED,
                        clinics: res.body.result,
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading BI games clinics", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getBiGamesDemoClinics() {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROVIDER_BI_GAMES_CLINICS_LOADING });

        ProviderApi.getDemoGameInvites()
            .then(res => {
                dispatch({
                    type: ActionTypes.PROVIDER_BI_GAMES_CLINICS_LOADED,
                    clinics: res.body,
                });
            })
            .catch(error => {
                handleErrorResponse("loading BI games clinics", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function updateClinicAlias(data, nodeId) {
    return (dispatch) => {
        ProviderApi.updateBiGamesClinicAlias(data)
            .then(res => {
                dispatch({
                    type: ActionTypes.PROVIDER_CLINIC_ALIAS_UPDATED,
                    clinic: data,
                    nodeId
                });
            })
            .catch(error => {
                handleErrorResponse("updating clinic alias", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getClinicRedemptions(searchData) {
    return (dispatch) => {
        ProviderApi.getClinicRedemptions(searchData)
            .then(res => {
                dispatch({
                    type: ActionTypes.REDEMPTIONS_LOADED,
                    data: mapRedemptionsFromServerToApp(res.body, searchData.year),
                    clinicId: searchData.clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("loading clinic redemptions", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getSnapshot(snapshotId, userId=null, isDemo=false) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.SNAPSHOT_LOADING });

        ProviderApi.getSnapshot(snapshotId, userId, isDemo)
            .then(res => {
                dispatch({
                    type: ActionTypes.SNAPSHOT_LOADED,
                    snapshotId: snapshotId,
                    snapshot: mapSingleGameFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("loading game snapshot", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}


export function getLast2Snapshots(gameId, userId=null, clinicId =null, isDemo = false) {
    return (dispatch) => {
        ProviderApi.getLast2Snapshots(gameId, userId, clinicId, isDemo)
            .then(res => {
                dispatch({
                    type: ActionTypes.TWO_SNAPSHOTS_LOADED,
                    gameId,
                    snapshots: mapClinicGamesFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                if (error.status === 404) {
                    dispatch({
                        type: ActionTypes.TWO_SNAPSHOTS_LOAD_ERROR,
                        gameId,
                    });
                } else {
                    handleErrorResponse("loading game snapshot", error);
                    dispatch({
                        type: ActionTypes.REQUEST_ERROR,
                        error,
                    });
                }
            });
    }
}

export function getProviderHierarchies(providerId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PROVIDER_HIERARCHY_LOADING })

        ProviderApi.getProviderHierarchies(providerId)
            .then(res => {
                dispatch({
                    type: ActionTypes.PROVIDER_HIERARCHY_LOADED,
                    providerId,
                    data: res.body,
                })
            })
            .catch((error) => {
                handleErrorResponse("Couldn't load provider hierarchy", error)
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function providerHierarchyLoaded(providerId, res) {
    return (dispatch) => {
            dispatch({
                type: ActionTypes.PROVIDER_HIERARCHY_LOADED,
                providerId,
                data: res.body,
            })
    };
}

export function reassignGame(data) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.REASSIGNING_GAME })

        ProviderApi.reassignGame(data)
            .then(res => {
                dispatch({
                    type: ActionTypes.GAME_REASSIGNED,
                    data: res.body,
                })
            })
            .catch((error) => {
                handleErrorResponse("reassigning game", error)
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function updateCorporateGroupVisibility(data) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());

        ProviderApi.updateCorporateGroupVisibility(data)
            .then(res => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.PROVIDER_UPDATED,
                    data,
                })
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "updating corporate group visibility")(error);
            });
    };
}
