import map from "lodash/map";
import * as ClinicApi from "api/ClinicApi";
import * as CouponsApi from "api/CouponsApi";
import * as VetCheckApi from "api/VetCheckApi";
import * as ApplicationStateActions from "actions/ApplicationStateActions";
import { getDefaultErrorHandler } from "actions/base";
import toast from "utils/toast";
import LocalData from "utils/LocalData";
import { handleErrorResponse } from "utils/request";
import * as ActionTypes from "constants/ActionTypes";
import {
    mapClinicDashboardFromServerToApp,
    mapClinicDvmCommissionsFromServerToApp,
    mapClinicGamesFromServerToApp,
    mapClinicPIMSUsersFromServerToApp,
    mapClinicPimsFromServerToApp,
    mapClinicsCoordinatesFromServerToApp,
    mapClinicsListFromServerToApp,
    mapCustomClinicViewsFromServerToApp,
    mapCustomClinicViewFromServerToApp,
    mapProductTagsFromServerToApp,
    mapProgramFromServerToApp,
    mapSingleClinicFromAppToServer,
    mapSingleClinicFromServerToApp,
} from "data/serverMapping";

export function getClinicById(clinicId, isAdminView) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());

        ClinicApi.getClinicById(clinicId)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.CLINIC_DETAILS_LOADED,
                    clinic: mapSingleClinicFromServerToApp(res.body),
                });

                if(!!res.body?.programs?.VetCheck?.enabled) {
                    dispatch(ApplicationStateActions.startWorking());
                    VetCheckApi.getVetCheckStatus(res.body.clinicId)
                        .then((results) => {
                            dispatch(ApplicationStateActions.endWorking());
                            dispatch({
                                type: ActionTypes.CLINIC_VETCHECK_STATUS_LOADED,
                                status: {
                                    ...results.body,
                                    clinicId: res.body.clinicId
                                }

                            });
                        })
                        .catch((error) => {
                            getDefaultErrorHandler(dispatch, "loading vetcheck status")(error);
                        });
                }
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading clinic details")(error);
            });
    };
}

export function getClinicInfo(clinicId, isProviderView) {
    return (dispatch) => {
        if(isProviderView) {
            dispatch({
                type: ActionTypes.START_ADMIN_CLINIC_DETAILS_LOAD,
            })
        }

        ClinicApi.getClinicInfo(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_DETAILS_LOADED,
                    clinic: mapSingleClinicFromServerToApp(res.body),
                });

                //TODO: FOR Provider view of vetcheck pages
                // logger.log(res.body);
                // VetCheckApi.getVetCheckStatus(clinic.id)
                //     .then((results) => {
                //         dispatch({
                //             type: ActionTypes.CLINIC_VETCHECK_STATUS_LOADED,
                //             status: {
                //                 ...results.body,
                //                 clinicId: clinic.id
                //             }
                //
                //         });
                //     })
                //     .catch((error) => {
                //         handleErrorResponse("loading vetcheck status", error);
                //     });
            })
            .catch((error) => {
                handleErrorResponse("loading clinic data", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function updateClinicSettings(clinicSettings, successCallback) {
    return (dispatch) => {
        ClinicApi.updateClinicSettings(mapSingleClinicFromAppToServer(clinicSettings))
            //TODO: The Api is not returning anything at this point
            .then((res) => {
                if(successCallback) {
                    successCallback()
                }

                dispatch({
                    type: ActionTypes.CLINIC_DETAILS_UPDATED,
                    clinicDetails: mapSingleClinicFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic settings", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function adminUpdateClinicSettings(clinicSettings) {
    return (dispatch) => {
        ClinicApi.adminUpdateClinicSettings(mapSingleClinicFromAppToServer(clinicSettings))
            .then((response) => {
                // Fetch the clinic Data fresh after making an update
                //  - gets the billingAddressId when a billing address was added
                ClinicApi.getClinicInfo(clinicSettings.clinicId)
                    .then((res) => {
                        dispatch({
                            type: ActionTypes.CLINIC_DETAILS_LOADED,
                            clinic:{
                                ...mapSingleClinicFromServerToApp(res.body),
                                // These fields have a delay when updating so we need to keep the values that were passed in.
                                excludedCouponClientIdList: clinicSettings.excludedCouponClientIdList,
                                excludedEmailClientIdList: clinicSettings.excludedEmailClientIdList,
                                excludedReportingClientIdList: clinicSettings.excludedReportingClientIdList,
                            },
                        });
                    })
                    .catch((e) => {
                        handleErrorResponse("loading clinic details", e);
                        dispatch({
                            type: ActionTypes.REQUEST_ERROR,
                            e,
                        });
                    });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic details", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function deleteClinic(clinicId) {
    return (dispatch) => {
        ClinicApi.deleteClinic(clinicId)
            .then((res) => {
                toast.success(`Clinic (${clinicId}) has been deleted successfully`);
                dispatch({
                    type: ActionTypes.CLINIC_DELETED,
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("deleting clinic", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getClinicDashboardStats(clinicId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_DASHBOARD_STATS_LOADING });
        ClinicApi.getClinicStatsFull(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_DASHBOARD_FULL_LOADED,
                    dashboard: mapClinicDashboardFromServerToApp(res.body),
                });
            })
            .catch(error => {
                handleErrorResponse("loading clinic dashboard", error);
            });
    }
}

export function getUpdatedClinicDashboardStats(clinicId) {
    return (dispatch) => {
        ClinicApi.getClinicStats(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_DASHBOARD_STATS_LOADED,
                    stats: res.body,
                });
            })
            .catch(error => {
                handleErrorResponse("loading clinic dashboard stats", error);
            });
    }
}

export function getTileDataByType(tileTypeId, timeRange) {
    return (dispatch) => {
        ClinicApi.getTileDataByType(tileTypeId, timeRange)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_DASHBOARD_TILE_DATA,
                    tileType: tileTypeId,
                    data: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("loading tile data", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getAllClinics() {
    return (dispatch) => {
        ClinicApi.searchClinics({ limit: 99999, offset: 0, orderBy: "clinicId", orderDir: "DESC" })
            .then((res) => {
                if (res.body) {
                    const { data, ...rest} = res.body;
                    dispatch({
                        type: ActionTypes.ALL_CLINICS_LOADED,
                        clinics: mapClinicsListFromServerToApp(data),
                        ...rest,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("searching clinics", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function loadClinicsList(searchParams) {
    const params = searchParams ? { ...searchParams } : { limit: 100, offset: 0, orderBy: "clinicId", orderDir: "DESC" }
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ClinicApi.searchClinics(params)
            .then((res) => {
                if (res.body) {
                    dispatch(ApplicationStateActions.endWorking());
                    const { data, ...rest} = res.body;
                    if (res.body) {
                        dispatch({
                            type: ActionTypes.CLINICS_LOADED,
                            clinics: mapClinicsListFromServerToApp(data),
                            ...rest,
                            params: params,
                        });
                    }
                }
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading clinics")(error);
            });
    }
}

/// See AdminClinicMapTable and ClinicsTable
export function setClinicsTableIds(clinicIds) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLINICS_TABLE_IDS_SET,
            ids: clinicIds,
        });
    };
}

/// See AdminClinicMapTable and ClinicsTable
export function clearClinicsTableIds() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLINICS_TABLE_IDS_CLEARED,
        });
    };
}

export function getRecentClinics() {
    return (dispatch) => {
        const clinics = LocalData.getRecentClinics();
        dispatch({
            type: ActionTypes.RECENTLY_VIEWED_CLINICS_LOADED,
            clinics,
        });
    };
}

export function setRecentClinic(clinic) {
    return (dispatch) => {
        LocalData.addRecentClinic(clinic);
        dispatch({
            type: ActionTypes.RECENTLY_VIEWED_CLINICS_UPDATED,
            clinic,
        });
    }
}

export function dismissRecentClinic(clinicId) {
    return (dispatch) => {
        LocalData.clearRecentClinic(clinicId);
        dispatch({
            type: ActionTypes.RECENTLY_VIEWED_CLINIC_DISMISSED,
            recentClinicId: clinicId,
        });
    };
}

export function clinicCreated(data) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLINIC_CREATED,
            clinic: data,
        });
    };
}

export function clinicUpdated(data) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLINIC_UPDATED,
            clinic: data,
        });
    };
}

export function enrollClinicProgram(clinicId, data, isRegistration=false) {
    return (dispatch) => {
        ClinicApi.enrollClinicInProgram(clinicId, data)
            .then(() => {
                toast.success("Successfully Enrolled in Program");
                if(isRegistration) {
                    dispatch({
                        type: ActionTypes.PROGRAM_ADDED_TO_CLINIC,
                    })
                } else {
                    if(data?.providerId === 1) {
                        CouponsApi.getPrograms(clinicId)
                            .then((res) => {
                                if (res.body) {
                                    dispatch({
                                        type: ActionTypes.PROGRAMS_LOADED,
                                        programs: mapProgramFromServerToApp(res.body),
                                    });
                                }
                            })
                            .catch((error) => {
                                handleErrorResponse("loading programs", error);
                                dispatch({
                                    type: ActionTypes.REQUEST_ERROR,
                                    error,
                                });
                            });
                    }

                    ClinicApi.getClinicInfo(clinicId)
                        .then((res) => {
                            if (res.body) {
                                dispatch({
                                    type: ActionTypes.CLINIC_DETAILS_LOADED,
                                    clinic: mapSingleClinicFromServerToApp(res.body)
                                });
                            }
                        })
                        .catch((error) => {
                            handleErrorResponse("loading clinic details", error);
                        });
                }
            })
            .catch((error) => {
                handleErrorResponse("enrolling clinic in program", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function programAddedToClinic() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.PROGRAM_ADDED_TO_CLINIC,
        })
    };
}

export function programsLoaded(programs) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.PROGRAMS_LOADED,
            programs: mapProgramFromServerToApp(programs),
        });
    };
}
export function clinicDetailsLoaded(clinic) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLINIC_DETAILS_LOADED,
            clinic: mapSingleClinicFromServerToApp(clinic)
        });
    };
}

export function submittingVoucher() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.VOUCHER_CODE_SUBMITTED,
        });
    }
}
export function validVoucher(data) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.VOUCHER_CODE_VERIFIED,
            response: data,
        });
    }
}

export function invalidVoucher() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.VOUCHER_CODE_REJECTED,
        });
    }
}

export function verifyVoucherCode(code, clinicId=null) {
    return (dispatch) => {

        // TODO: Fix api call
        dispatch({
            type: ActionTypes.VOUCHER_CODE_SUBMITTED,
            code: code,
        });
        ClinicApi.verifyVoucherCode(code, clinicId)
            .then((res) => {
                if (res.body.isValid) {
                    dispatch({
                        type: ActionTypes.VOUCHER_CODE_VERIFIED,
                        response: res.body,
                    });
                } else {
                    toast.error(res.body.status);
                    dispatch({
                        type: ActionTypes.VOUCHER_CODE_REJECTED,
                        limit: res.body.limit,
                    })
                }
            })
            .catch((error) => {
                handleErrorResponse("when verifying voucher code", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function clearVoucherCode() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.VOUCHER_CODE_CLEARED,
        });
    }
}

export function getClinicUsers(clinicId) {
    return (dispatch) => {
        ClinicApi.getClinicUsers(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_USERS_LOADED,
                    clinicUsers: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("loading clinic users", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function updateClinicUser(clinicId, clinicUserUpdate) {
    return (dispatch) => {
        ClinicApi.updateClinicUser(clinicId, clinicUserUpdate)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_USER_UPDATED,
                    clinicUser: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic user", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function createClinicUser(clinicId, clinicUser) {
    return (dispatch) => {
        ClinicApi.createClinicUser(clinicId, clinicUser)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_USER_CREATED,
                    clinicUser: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("creating clinic user", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getGreenlineGames(clinicId = null) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.LOADING_CLINIC_GAMES,
        });
        ClinicApi.getGreenlineGames(clinicId)
            .then(res => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.CLINIC_GAMES_LOADED,
                        games: mapClinicGamesFromServerToApp(res.body),
                    });
                }
            })
            .catch(error => {
                handleErrorResponse("loading games", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function clearClinicsSearch() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLINIC_SEARCH_CLEARED,
        })
    }
}

export function searchClinicsByName(searchTerm) {
    return (dispatch) => {
        ClinicApi.searchClinics({search:searchTerm, offset:0, limit:100})
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.CLINIC_SEARCH_RESULTS_LOADED,
                        query: searchTerm,
                        results: res.body,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("searching clinics", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

/// Get Google Map data for all clinics for all providers
export function getAdminMappedClinics() {
    return (dispatch) => {
        ClinicApi.getAdminMappedClinics()
            .then((res) => {
                dispatch({
                    type: ActionTypes.ADMIN_CLINIC_MAP_DETAILS_LOADED,
                    clinicsMapDetails: mapClinicsCoordinatesFromServerToApp(res.body)
                })
            })
            .catch((error) => {
                handleErrorResponse("loading map", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error
                });
            });
    };
}

export function getClinicReps(clinicId) {
    return (dispatch) => {
        ClinicApi.getReps(clinicId)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.CLINIC_REPS_LOADED,
                        results: res.body,
                        clinicId: clinicId,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading clinic reps", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}
export function getClinicHospitals(clinicId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_HOSPITALS_LOADING });

        ClinicApi.getHospitals(clinicId)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.CLINIC_HOSPITALS_LOADED,
                        results: res.body,
                        clinicId: clinicId,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading clinic hospitals", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function assignHospital(data) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_HOSPITALS_LOADING });

        ClinicApi.assignHospital(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_HOSPITAL_ASSIGNED,
                    hospital: data,
                })
            })
        .catch((error) => {
                handleErrorResponse("assigning hospital to clinic", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function loadTags() {
    return (dispatch) => {
        ClinicApi.loadTags()
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.TAGS_LOADED,
                        results: res.body,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading tags", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function tagCreated(tag) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.TAG_CREATED,
            tag,
        });
    }
}

export function updateClinicTags(clinicId, tags) {
    return (dispatch) => {
        ClinicApi.updateClinicTags(clinicId, tags)
            .then(res => {
                dispatch({
                    type: ActionTypes.CLINIC_TAGS_UPDATED,
                    clinicId,
                    tags: JSON.parse(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic tags", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getPIMSForClinic(clinicId) {
    return (dispatch) => {
        ClinicApi.loadPIMSForClinic(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_PIMS_CONFIG_LOADED,
                    clinicPim: mapClinicPimsFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("loading clinic PIMS", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateClinicPIMS(data) {
    return (dispatch) => {
        ClinicApi.updatePIMSForClinic(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_PIMS_CONFIG_UPDATED,
                    clinicPim: mapClinicPimsFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic PIMS", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function generateInstallCode(clinicId) {
    return (dispatch) => {
        ClinicApi.generateInstallCode(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.INSTALL_CODE_GENERATED,
                    installCode: res.body,
                    clinicId: clinicId,
                });
            })
            .catch((error) => {
                handleErrorResponse("generating install code", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getDefaultPIMSConfigs() {
    return async (dispatch) => {
        try {
            const response = await ClinicApi.loadDefaultPIMSConfigurations();
            dispatch({
                type: ActionTypes.PIMS_CONFIGS_LOADED,
                results: response.body,
            })
        } catch (e) {
            handleErrorResponse("loading default PIMS config", error);
            dispatch({
                type: ActionTypes.REQUEST_ERROR,
                error: e,
            });
        }
    };
}

export function getProductBrands(query) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ClinicApi.getProductBrands(query)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                if (res.body) {
                    dispatch({
                        type: ActionTypes.BRANDS_LOADED,
                        data: res.body,
                    });
                }
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading product brands")(error);
            });
    }
}

export function getProviderLocations(clinicId, showOnlyEnrolled=true) {
    return (dispatch) => {
        ClinicApi.getProviderLocations(clinicId, showOnlyEnrolled)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_PROVIDER_LOCATIONS_LOADED,
                    clinicId,
                    data: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("loading provider locations", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}
//data.clinicId, data.providerId, data.locationId
export function setClinicProviderLocation(data) {
    const {clinicId, providerId, ...locationData} = data;
    return (dispatch) => {
        ClinicApi.setClinicProviderLocation(clinicId, providerId, locationData.locationId)
            .then((res) => {
                toast.success(`Clinic location ${location ? "mapped" : "cleared"} successfully`);
                dispatch({
                    type: ActionTypes.CLINIC_PROVIDER_LOCATION_UPDATED,
                    clinicId,
                    providerId,
                    data: locationData ? { locationId: locationData.locationId, locationName: locationData.name} : null,
                });
            })
            .catch((error) => {
                handleErrorResponse("setting clinics provider location", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function loadClinicPIMSUsers(clinicId) {
    // This gets the list of DVMs (NOT commission data - that's a different API endpoint)
    return (dispatch) => {
        ClinicApi.loadClinicPIMSUsers(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PIMS_USERS_LOADED,
                    data: mapClinicPIMSUsersFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("loading clinic PIMS users", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateClinicPIMSUser(data) {
    return (dispatch) => {

        dispatch({
            type: ActionTypes.PIMS_USERS_UPDATING,
            pimsUserId: data.pimsUserId,
        });

        ClinicApi.updateClinicPIMSUser(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PIMS_USERS_UPDATED,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic PIMS user", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getClinicProductTags(clinicId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_PRODUCT_TAGS_LOADING });

        ClinicApi.getClinicProductTags(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_PRODUCT_TAGS_LOADED,
                    data: mapProductTagsFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("loading clinic product tags", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateClinicProductTags(clinicProductId, productTagIds=[]) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_PRODUCT_TAGS_LOADING });

        ClinicApi.updateClinicProductTags(clinicProductId, productTagIds)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_PRODUCT_TAGS_UPDATED,
                    data: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic product tags", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function searchClinicProductTags(query) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLINIC_PRODUCT_TAGS_SEARCHED,
            search: query,
        })
    }
}

export function clearClinicProductTagsSearch() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLINIC_PRODUCT_TAGS_SEARCH_CLEARED,
        })
    }

}

export function loadTaxRates(clinicId) {
    return (dispatch) => {
        ClinicApi.loadTaxRates(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_TAX_RATES_LOADED,
                    data: res.body.taxHistory,
                    clinicId: res.body.clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("loading tax rates", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function batchUpdateClinicTax(data) {
    return (dispatch) => {
        ClinicApi.batchUpdateClinicTax(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_TAX_RATES_UPDATED,
                    data: res.body.taxHistory,
                    clinicId: res.body.clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic tax rates", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getClinicDvmCommissions(clinicId) {
    return (dispatch) => {
        ClinicApi.getClinicDvmCommissions(clinicId)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.WELLNESS_DVM_COMMISSIONS_LOADED,
                        commissions: mapClinicDvmCommissionsFromServerToApp(res.body),
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading DVM commissions", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateClinicDvmCommission(update) {
    return (dispatch) => {
        ClinicApi.updateClinicDvmCommission(update)
            .then((res) => {
                dispatch({
                    type: ActionTypes.WELLNESS_DVM_COMMISSION_UPDATED,
                    commission: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating DVM commissions", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function addClinicDvmCommission(commission) {
    return (dispatch) => {
        ClinicApi.addClinicDvmCommission(commission)
            .then((res) => {
                dispatch({
                    type: ActionTypes.WELLNESS_DVM_COMMISSION_ADDED,
                    commission: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("adding clinic DVM commission", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function deleteClinicDvm(dvmId) {
    return (dispatch) => {
        ClinicApi.deleteClinicDvm(dvmId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.WELLNESS_DVM_COMMISSION_DELETED,
                    dvmId,
                });
            })
            .catch((error) => {
                handleErrorResponse("deleting clinic DVM commission", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function loadPricingSchedule(clinicId) {
    return (dispatch) => {
        ClinicApi.loadClinicPriceSchedules(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_PRICING_SCHEDULE_LOADED,
                    clinicId,
                    data: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("loading pricing schedules", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function batchUpdateClinicPriceSchedules(clinicId, newFormData) {
    return (dispatch) => {
        ClinicApi.batchUpdateClinicPriceSchedules(clinicId, newFormData)
            .then(() => {
                //Because there is nothing returned from the API at this point, we have to reload the pricing schedules to update them in the UI.
                ClinicApi.loadClinicPriceSchedules(clinicId)
                    .then((res) => {
                        dispatch({
                            type: ActionTypes.CLINIC_PRICING_SCHEDULE_LOADED,
                            data: res.body,
                            clinicId,
                        });
                    })
                    .catch((error) => {
                        handleErrorResponse("loading clinic price schedules", error);
                        dispatch({
                            type: ActionTypes.REQUEST_ERROR,
                            error,
                        });
                    });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic price schedules", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getClinicDvmCommissionHistory(clinicDvmId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ClinicApi.getClinicDvmCommissionHistory(clinicDvmId)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.WELLNESS_DVM_COMMISSION_HISTORY_LOADED,
                    clinicDvmId: clinicDvmId,
                    history: res.body,
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading clinic DVM commission history")(error);
            });
    }
}

export function batchUpdateCommissionHistory(clinicDvmId, data) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ClinicApi.batchUpdateCommissionHistory(clinicDvmId, data)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.WELLNESS_DVM_COMMISSION_HISTORIES_UPDATED,
                    histories: res.body,
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "updating commission history")(error);
            });
    }
}

export function getCustomClinicViews() {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ClinicApi.getCustomClinicViews()
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.CLINIC_VIEWS_LOADED,
                    data: mapCustomClinicViewsFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading custom clinic views")(error);
            });
    }
}
export function customClinicViewCreated(view) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLINIC_VIEW_CREATED,
            data: mapCustomClinicViewFromServerToApp(view),
        });
    }
}

export function updateCustomClinicView(view) {
    return (dispatch) => {
        ClinicApi.updateCustomClinicView(view)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_VIEW_UPDATED,
                    data: mapCustomClinicViewFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("updating custom clinics view", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function deleteCustomClinicView(userPreferenceId) {
    return (dispatch) => {
        ClinicApi.deleteCustomClinicView(userPreferenceId)
            .then((res) => {
                toast.success("Clinic view deleted");
                dispatch({
                    type: ActionTypes.CLINIC_VIEW_DELETED,
                    userPreferenceId,
                });
            })
            .catch((error) => {
                handleErrorResponse("deleting custom clinics view", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getClinicSupportFiles() {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        ClinicApi.getClinicSupportFiles()
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.CLINIC_SUPPORT_FILES_LOADED,
                    supportFiles: res.body,
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading clinic support files")(error);
            });
    }
}

export function loadClinicWellnessPlans(clinicId, providerId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_WELLNESS_PLANS_LOADING });

        ClinicApi.loadClinicWellnessPlanInstances(clinicId, providerId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_WELLNESS_PLANS_LOADED,
                    wellnessPlans: res.body,
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("loading clinic wellness plans", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function loadClinicWellnessPlanInstances(clinicId, providerId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_WELLNESS_PLAN_INSTANCES_LOADING });

        ClinicApi.loadClinicWellnessPlanInstances(clinicId, providerId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_WELLNESS_PLAN_INSTANCES_LOADED,
                    wellnessPlans: res.body,
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("loading clinic wellness plan instances", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function addedClinicWellnessPlan(clinicId, plan) {
    return (dispatch) => {
        dispatch({type: ActionTypes.CLINIC_WELLNESS_PLAN_INSTANCE_ADDED, plan, clinicId});
    }
}

export function updateClinicWellnessPlans(clinicId, data, isLast=false) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_WELLNESS_PLANS_LOADING });

        ClinicApi.updateClinicWellnessPlans(clinicId, data)
            .then((res) => {
                if(isLast) {
                    ClinicApi.loadClinicWellnessPlans(clinicId)
                        .then((res) => {
                            dispatch({
                                type: ActionTypes.CLINIC_WELLNESS_PLANS_LOADED,
                                wellnessPlans: res.body,
                                clinicId
                            });
                        })
                        .catch((error) => {
                            handleErrorResponse("loading clinic wellness plan", error);
                            dispatch({
                                type: ActionTypes.REQUEST_ERROR,
                                error,
                            });
                        });
                }
            })
            .catch((error) => {
                handleErrorResponse("updating clinic wellness plans", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateWellnessStartDate(clinicId, providerId, data) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_WELLNESS_PLANS_LOADING });

        ClinicApi.updateWellnessStartDate(clinicId, providerId, data)
            .then((res) => {
                // dispatch({
                //     type: ActionTypes.CLINIC_WELLNESS_PLAN_HOUSE_ACCOUNT_UPDATED,
                //     houseAccountClinicDvmId,
                //     providerId,
                //     clinicId
                // });
            })
            .catch((error) => {
                handleErrorResponse("updating wellness start date", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateHouseAccount(clinicId, houseAccountClinicDvmId, providerId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_WELLNESS_PLANS_LOADING });

        ClinicApi.updateHouseAccount(clinicId, houseAccountClinicDvmId, providerId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_WELLNESS_PLAN_HOUSE_ACCOUNT_UPDATED,
                    houseAccountClinicDvmId,
                    providerId,
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic House Account", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function batchUpdateClinicWellnessPlans(clinicId, data) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_WELLNESS_PLANS_LOADING });

        ClinicApi.batchUpdateClinicWellnessPlans(clinicId, data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_WELLNESS_PLANS_LOADED,
                    wellnessPlans: res.body,
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic wellness plans", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function loadSoftwareVendors() {
    return (dispatch) => {
        dispatch({ type: ActionTypes.SOFTWARE_VENDORS_LOADING });

        ClinicApi.loadSoftwareVendors()
            .then((res) => {
                dispatch({
                    type: ActionTypes.SOFTWARE_VENDORS_LOADED,
                    data: res.body
                });
            })
            .catch((error) => {
                handleErrorResponse("loading software vendors", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getAvailableReports(clinicId, providerId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_REPORTS_LOADING });

        ClinicApi.getAvailableReports(clinicId, providerId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_REPORTS_LOADED,
                    clinicId: clinicId,
                    providerId: providerId,
                    data: res.body
                });
            })
            .catch((error) => {
                handleErrorResponse("loading available reports", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateAvailableReports(data) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CLINIC_REPORTS_LOADING });

        ClinicApi.updateAvailableReports(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_REPORTS_UPDATED,
                    data
                });
            })
            .catch((error) => {
                handleErrorResponse("updating available reports", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getExternalPrices() {
    return (dispatch) => {
        ClinicApi.getExternalPrices()
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_PRICE_COMPARISONS_LOADED,
                    brands: res.body
                });
            })
            .catch((error) => {
                handleErrorResponse("loading price comparisons", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getFavoriteBrands() {
    return (dispatch) => {
        const favorites = LocalData.getFavoriteBrands();
        dispatch({
            type: ActionTypes.BRAND_FAVORITES_LOADED,
            favorites,
        });
    }
}

export function addFavoriteBrand(brandId) {
    return (dispatch) => {
        LocalData.addFavoriteBrand(brandId);
        dispatch({
            type: ActionTypes.BRAND_FAVORITE_ADDED,
            favorite: brandId,
        });
    }
}

export function removeFavoriteBrand(brandId) {
    return (dispatch) => {
        LocalData.clearFavoriteBrand(brandId);
        dispatch({
            type: ActionTypes.BRAND_FAVORITE_REMOVED,
            favorite: brandId,
        });
    };
}

export function searchClinicManagement(searchValue) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLINIC_MANAGEMENT_SEARCH,
            search: searchValue,
        });
    };
}

export function loadLookerReports(clinicId) {
    return (dispatch) => {
        ClinicApi.loadLookerReports(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.LOOKER_REPORTS_LOADED,
                    clinicId,
                    reports: res.body
                });
            })
            .catch((error) => {
                handleErrorResponse("loading looker reports", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}
